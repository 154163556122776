import React, { useState } from "react";
import { withUnpublishedPreview } from "gatsby-source-prismic";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { createBreakpoint } from "react-use";

// Components
import { AllListings } from "../components/maps/all-listings-map";

// Templates
import City from "../templates/city";
import Country from "../templates/country";
import Listing from "../templates/listing";

const Page = styled.div`
  padding: 0 90px;
  margin: 0 0 85px 0;

  @media (max-width: 1200px) {
    padding: 0 60px;
  }

  @media (max-width: 1000px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  & h1 {
    margin: 30px 0 0 0;
  }
`;

const ContentContainer = styled.div`
  & h1 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.05em;
  }
`;

const DirectoryLink = styled.h3`
  margin: 220px 0 0 0;

  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.05em;

  & a {
    text-decoration: none;
    border-bottom: 2px solid rgb(14 53 99);
  }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.05em;
  }
`;

const Line = styled.hr`
  height: 1px;

  background-image: linear-gradient(
    to right,
    #0e3563 33%,
    rgba(14, 53, 99, 0) 0%
  );

  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;

  margin: 25px 0 0 0;
  padding: 0;
  border: 0;
`;

const useBreakpoint = createBreakpoint({ M: 601, S: 10 });

const NotFoundPage = ({ data }) => {
  const breakpoint = useBreakpoint();

  return (
    <Page>
      <ContentContainer>
        <h1 className="transcript">Page not found!</h1>

        <DirectoryLink className="transcript">
          Discover the <Link to={`/`}>directory</Link>
        </DirectoryLink>
        <Line />

        <AllListings
          locations={data.allPrismicListing.edges}
          mapHeight={breakpoint === "M" ? 500 : 350}
        />
      </ContentContainer>
    </Page>
  );
};

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    city: City,
    country: Country,
    listing: Listing,
  },
});

export const query = graphql`
  {
    allPrismicListing(sort: { fields: last_publication_date, order: DESC }) {
      edges {
        node {
          id
          url
          data {
            title {
              text
            }
            thumbnail {
              alt
              dimensions {
                height
                width
              }
              fluid {
                srcWebp
                srcSetWebp
              }
            }
            city {
              document {
                ... on PrismicCity {
                  id
                  url
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            location {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;
